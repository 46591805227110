import { useState, useEffect, useRef, useCallback } from 'react'
import usePassiveLayoutEffect from '@react-hook/passive-layout-effect'

const useIntersectionObserver = (options = {}) => {
  const {
    root = null,
    pollInterval = null,
    useMutationObserver = false,
    rootMargin = '0px 0px 0px 0px',
    threshold = 0,
    initialIsIntersecting = false,
  } = options

  const didMount = useRef(null)
  const [element, setElement] = useState(null)
  const [entry, setEntry] = useState(() => ({
    boundingClientRect: null,
    intersectionRatio: 0,
    intersectionRect: null,
    isIntersecting: initialIsIntersecting,
    rootBounds: null,
    target: null,
    time: 0,
  }))

  const createObserver = useCallback(() => {
    if (typeof IntersectionObserver === 'undefined') return null
    const observer = new IntersectionObserver(entries => {
      setEntry(entries[entries.length - 1])
      return { root, rootMargin, threshold }
    })
    observer.POLL_INTERVAL = pollInterval
    observer.USE_MUTATION_OBSERVER = useMutationObserver
    return observer
  }, [pollInterval, root, rootMargin, threshold, useMutationObserver])

  const [observer, setObserver] = useState(createObserver)

  useEffect(() => {
    if (didMount.current === false) didMount.current = true
    else setObserver(createObserver())
    const { current } = didMount
    return () => {
      current === true && observer && observer.disconnect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [root, rootMargin, pollInterval, useMutationObserver, createObserver])

  usePassiveLayoutEffect(() => {
    element && observer && observer.observe(element)
    return () => {
      element && observer && observer.unobserve(element)
    }
  }, [element, observer])

  return [entry, setElement]
}

export default useIntersectionObserver
