/** @jsx jsx */
import { jsx } from 'theme-ui'

import CategoryHeadingModule from '@uswitch/trustyle.category'
import Breadcrumb from '@c/breadcrumb'
import connect from '@h/connect-redux'
import { getModuleFromIncludes, includes } from '@r/contentful-selectors'
import { contentId } from '@u/contentful'
import ImgixImg from '@h/imgix-image'

const Figure = props => {
  const Breadcrumbs = props.breadcrumbs && <Breadcrumb entity={props.breadcrumbs} />
  const Image = props.image && (
    <ImgixImg
      src={props.image?.file?.url}
      alt={props.image?.title}
      sx={{
        maxHeight: '180px',
        display: 'block',
        marginLeft: 'auto',
        marginRight: ['auto', 0],
      }}
    />
  )

  return <CategoryHeadingModule {...props} breadcrumbs={Breadcrumbs} image={Image} />
}

export default connect((state, props) => {
  const image = getModuleFromIncludes(includes(state), contentId(props.image)).fields
  return { image }
})(Figure)
