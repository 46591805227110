import React from 'react'
import { Container } from '@uswitch/trustyle.flex-grid'
import EmbeddedVideo from '@uswitch/trustyle.embedded-video'

export default ({ useContainer = false, ...props }) => {
  return useContainer ? (
    <Container>
      <EmbeddedVideo {...props} />
    </Container>
  ) : (
    <EmbeddedVideo {...props} />
  )
}
