/** @jsx jsx */
import { jsx } from 'theme-ui'
import { useEntity } from '@h/use-entity'
import { Container } from '@uswitch/trustyle.flex-grid'
import Hero from '@uswitch/trustyle.hero'

import { cf2imgix } from '@h/imgix-image'
import Breadcrumb from '@c/breadcrumb'
import Grid from '@c/grid-from-json.connected'

const ConnectedHero = props => {
  const { imagePosition } = props
  const image = useEntity(props.image)

  const breadcrumbs = <Breadcrumb entity={props.breadcrumbs} />

  return (
    <Hero
      breadcrumbs={props.breadcrumbs && breadcrumbs}
      container={Container}
      fgImage={cf2imgix(image?.file?.url)}
      fgImagePosition={imagePosition.position}
      fgImageType={imagePosition.imageType}
      fgImageOnMobile={imagePosition.displayOnMobile}
      variant={props.variant}
    >
      <Grid {...props} />
    </Hero>
  )
}

export default props => <ConnectedHero {...props} />
