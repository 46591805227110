import connect from '@h/connect-redux'
import { meta, pathname } from '@r/app-selectors'
import { breadcrumb, getModuleFromIncludes } from '@r/contentful-selectors'

import { pageTitle, contentId } from '@u/contentful'

export default Component =>
  connect((state, props) => {
    const pageId = contentId(state.contentful.ctx.items[0].fields.link)
    const page = getModuleFromIncludes(state.contentful.ctx.includes, pageId)
    const homeTitle = meta(state)?.breadcrumbs?.homeTitle
    const path = pathname(state)
    const homePath = meta(state)?.breadcrumbs?.homePath

    const breadcrumbs = breadcrumb(state)
    const [pageCrumb] = breadcrumbs.slice(-1)

    const crumbs = breadcrumbs.filter(bc => bc.fields.path !== path)
    const breadcrumbTitle =
      pageCrumb.fields.path === pathname(state) ? pageCrumb.fields.displayText : pageTitle(page)

    return {
      crumbs,
      title: meta(state)?.breadcrumbs?.showPageTitle ? breadcrumbTitle : null,
      ...(homeTitle ? { customHomeIcon: homeTitle } : {}),
      homePath,
    }
  })(Component)
