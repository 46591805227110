/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui'

import { path } from '@u/obj'

import { Container } from '@uswitch/trustyle.flex-grid'
import HeroWithBackgroundImg from '@uswitch/trustyle.hero-with-background-image'

import Breadcrumb from '@c/breadcrumb'
import Grid from '@c/grid-from-json.connected'
import { useEntity } from '@h/use-entity'

const WrappedHeroWithBackgroundImg = ({ breadcrumbs, children, content, image, layout }) => {
  const { theme } = useThemeUI()
  const imageEntity = useEntity(image)
  const { contentCols = 12, contentSpan = 12 } = path(
    ['modules', 'hero-with-background-image', 'layout'],
    theme,
    {},
  )

  const breadcrumbComponent = (
    <If condition={breadcrumbs}>
      <Breadcrumb entity={breadcrumbs} variant='light' />
    </If>
  )

  const containerStyles = {
    '> *': {
      height: '100%',
      alignContent: breadcrumbs ? 'space-between' : 'flex-end',
      variant: 'modules.hero-with-background-image.container-child',
    },
    variant: 'modules.hero-with-background-image.container',
  }

  const imageUrl = imageEntity?.file?.url

  return (
    <HeroWithBackgroundImg
      sx={{
        variant: imageUrl === undefined && 'modules.hero-with-background-image.variants.no-image',
      }}
      imageUrl={imageUrl}
    >
      <Container cols={contentCols} span={contentSpan} sx={containerStyles}>
        <Grid prependContent={breadcrumbComponent} content={content} layout={layout} />
      </Container>
    </HeroWithBackgroundImg>
  )
}

export default WrappedHeroWithBackgroundImg
