/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui'
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'
import { Col, Container, Row } from '@uswitch/trustyle.flex-grid'
import { toKebabCase } from '@u/string'
import GuideNav from '@c/guide-nav'
import useIntersectionObserver from '@h/use-intersection-observer'
import RichTextBlock from '@e/rich-text-block'
import useMeta from '@h/use-meta'

import { path } from '@u/obj'

const intersectionObserverOptions = {
  thresholds: [0, 1],
  rootMargin: '0px 0px 1000000px 0px',
}

const getWaypoints = obj =>
  obj?.content?.reduce((result, node) => {
    if (node.nodeType === 'heading-2') {
      const text = documentToPlainTextString(node)
      if (text) {
        result.push({ node, text, kebabText: toKebabCase(text) })
      }
    }
    return result
  }, [])

const getObservedWaypoints = waypoints =>
  waypoints?.reduce(
    (observed, decoratedNode) => ({
      ...observed,
      [decoratedNode.kebabText]: useIntersectionObserver(intersectionObserverOptions),
    }),
    {},
  )

export default ({
  content: document,
  sideNavLinks = [],
  hasSideBar,
  sideBarTitle = 'In this guide',
  textAlign = 'left',
}) => {
  const { theme } = useThemeUI()
  const waypoints = getWaypoints(document || {})
  const observedWaypoints = getObservedWaypoints(waypoints)
  const contentSpan = (theme?.modules && theme?.modules['rich-text']?.contentSpan) || 12
  const contentCols = (theme?.modules && theme?.modules['rich-text']?.contentCols) || 12

  const compound = path(['compounds', 'side-nav'], theme, {})
  const layout = path(['compounds', 'side-nav', 'layout'], theme, {})
  const textLayout = path(['compounds', 'side-nav', 'layout', 'text'], theme, [12, 12, 8])
  const navLayout = path(['compounds', 'side-nav', 'layout', 'nav'], theme, [12, 12, 4])

  const sideBarMeta = useMeta('sideBar')

  return (
    <Container
      cols={contentCols}
      span={contentSpan}
      sx={{
        textAlign,
        display: textAlign === 'center' && 'flex',
        justifyContent: textAlign === 'center' && 'center',
      }}
    >
      <Choose>
        <When condition={hasSideBar}>
          <Row
            cols={[12, 12, 12]}
            sx={{
              flexDirection: ['column-reverse', 'column-reverse', 'row'],
              ...layout,
            }}
          >
            <Col span={textLayout}>
              <If condition={document}>
                <RichTextBlock content={document} extraData={{ observedWaypoints }} />
              </If>
            </Col>
            <Col span={navLayout}>
              <div
                className={sideBarMeta?.isSticky ? 'sticky' : ''}
                data-breakpoints={theme.breakpoints}
                sx={{
                  top: 16,
                  marginBottom: 16,
                  paddingLeft: [0, 16, 16],
                  marginLeft: [0, 0, 16],
                  borderLeftWidth: ['0', null, '1px'],
                  borderLeftStyle: 'solid',
                  borderLeftColor: 'grey-20',
                  'nav>div': {
                    mt: 0,
                  },
                  ...compound,
                }}
              >
                <GuideNav
                  internalLinks={{
                    title: sideBarTitle,
                    links: waypoints.map((decoratedNode, index) => {
                      return {
                        text: decoratedNode.text,
                        url: `#${decoratedNode.kebabText}`,
                      }
                    }),
                  }}
                  linkLists={sideNavLinks}
                />
              </div>
            </Col>
          </Row>
        </When>
        <Otherwise>
          <div
            sx={{
              width: textAlign === 'center' && ['85%', '60%', '60%'],
            }}
          >
            <If condition={document}>
              <RichTextBlock content={document} extraData={{ observedWaypoints }} />
            </If>
          </div>
        </Otherwise>
      </Choose>
    </Container>
  )
}
