/** @jsx jsx */
import { jsx } from 'theme-ui'
import { useEntity } from '@h/use-entity'
import { Container } from '@uswitch/trustyle.flex-grid'
import HeroSecondary from '@uswitch/trustyle.hero-secondary'

import { cf2imgix } from '@h/imgix-image'
import Breadcrumb from '@c/breadcrumb'
import Grid from '@c/grid-from-json.connected'

const ConnectedHeroSecondary = props => {
  const image = useEntity(props.image)

  const breadcrumbs = <Breadcrumb entity={props.breadcrumbs} />

  return (
    <HeroSecondary
      breadcrumbs={props.breadcrumbs && breadcrumbs}
      container={Container}
      image={cf2imgix(image?.file?.url)}
      imageOnMobile={props.displayImageOnMobile}
      imageOnTablet={props.displayImageOnTablet}
    >
      <Grid {...props} />
    </HeroSecondary>
  )
}

export default props => <ConnectedHeroSecondary {...props} />
