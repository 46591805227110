/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui'
import Hero from '@uswitch/trustyle.hero'
import { useEntity } from '@h/use-entity'
import Breadcrumb from '@c/breadcrumb'
import Grid from '@c/grid-from-json.connected'

export default ({ breadcrumbs, content, image, layout }) => {
  const imageUrl = image ? useEntity(image).file?.url : null
  const theme = useThemeUI()
  const bottomImageOverflow = theme.theme.elements.hero?.bottomImageOverflow

  return (
    <Hero
      fgImage={imageUrl}
      breadcrumbs={breadcrumbs && <Breadcrumb {...breadcrumbs} />}
      bottomImageOverflow={bottomImageOverflow}
    >
      <div
        sx={{
          paddingBottom: bottomImageOverflow,
        }}
      >
        <Grid content={content} layout={layout} />
      </div>
    </Hero>
  )
}
