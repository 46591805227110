/** @jsx jsx */
import { jsx } from 'theme-ui'

import connectEntity from '@h/connect-entity'
import { ContentfulModule } from '@h/modules'
import { GridFromJson } from '@uswitch/trustyle.flex-grid'
import { contentId } from '@u/contentful'

const ConnectedGrid = connectEntity((state, props) => ({
  json: props.layout,
}))(GridFromJson)

const Grid = ({ content, prependContent, layout, ...props }) => {
  const childrenArray = [prependContent]
    .concat(content.map(entity => <ContentfulModule props={{ entity }} key={contentId(entity)} />))
    .filter(i => i)

  return <ConnectedGrid entity={layout} childrenArray={childrenArray} {...props} />
}

export default Grid
