/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui'
import { ModulesToReactComponents } from '@h/modules'
import Accordion from '@uswitch/trustyle.accordion'
import connectEntity from '@h/connect-entity'

import { path } from '@u/obj'
import { useEntity } from '@h/use-entity'
import { cf2imgix } from '@h/imgix-image'

const Component = ({ title, content, key, index, icon, ...props }) => {
  const iconImg = useEntity(icon)
  const iconSrc = cf2imgix(iconImg.file?.url)

  return (
    <Accordion icon={iconSrc} title={title} index={index} key={key}>
      {ModulesToReactComponents(content.content)}
    </Accordion>
  )
}

const ConnectedAccordion = connectEntity()(Component)

export default ({ title, items }) => {
  const { theme } = useThemeUI()
  const iconOpen =
    path(['compounds', 'accordion', 'iconOpen'], theme) || path(['accordion', 'iconOpen'], theme)
  const iconClosed =
    path(['compounds', 'accordion', 'iconClosed'], theme) ||
    path(['accordion', 'iconClosed'], theme)

  return (
    <div>
      <Accordion.Group iconOpen={iconOpen} iconClosed={iconClosed}>
        <Accordion.Title as='h2'>{title}</Accordion.Title>
        <For each='item' of={items} index='idx'>
          <ConnectedAccordion entity={item} key={idx}></ConnectedAccordion>
        </For>
      </Accordion.Group>
    </div>
  )
}
