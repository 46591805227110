import SideNav from '@uswitch/trustyle.side-nav'
import connect from '@h/connect-redux'

const getInclude = ({ contentful: { ctx: { includes = {} } = {} } = {} }) => id => ({
  ...includes[id].fields,
})

export default connect((state, props) => {
  const { linkLists = [] } = props
  const linkListWithFields = linkLists.map(({ sys: { id } }) => getInclude(state)(id))
  const linkListWithLinks = linkListWithFields.map(linkGroup => ({
    ...linkGroup,
    links: linkGroup.links.map(({ sys: { id } }) => ({
      text: getInclude(state)(id).title,
      url: getInclude(state)(id).url,
    })),
  }))
  return {
    additionalLinks: linkListWithLinks,
  }
})(SideNav)
